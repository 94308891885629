<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-29 16:17:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="container">
    <div class="topBox">
      <img class="leftImg" src="../../static/images/mingtai/topTxt.png" />
      <img class="rightImg" src="../../static/images/mingtai/loginRight.png" />
    </div>
    <div class="centBox">
      <div class="loginBox">
        <div class="tabBox">
          <div
            v-for="(item, index) in tabData"
            :key="index"
            @click="tabCk(index)"
            :class="{ active: index == tabIndex }"
          >
            {{ item }}
          </div>
        </div>
        <div class="boxs">
          <a-spin :spinning="confirmLoading">
            <a-form
              :form="form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
              @submit="handleSubmit"
            >
              <a-form-item
                style="display: none;"
              >
                <a-input v-decorator="['id']" />
              </a-form-item>
              <a-form-item label="姓名" v-if="tabIndex == 0">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      rules: [{ required: true, message: '请输入姓名' }],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item label="企业名称" v-else>
                <a-input
                  v-decorator="[
                    'name',
                    {
                      rules: [{ required: true, message: '请输入企业名称' }],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item label="身份证"  v-if="tabIndex == 0">
                <a-input
                  v-decorator="['idCard',{rules: [{ required: true, message: '请输入身份证！' },{ pattern:/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,message:'请输入正确的身份证' }]}]" 
                />
              </a-form-item>
              <a-form-item
                label="营业执照"
                v-else
                >
                  <a-upload
                      name="coverAvatar"
                      accept="image/jpeg,	image/png,	image/gif,	image/tiff"
                      :customRequest="customRequestCover"
                      list-type="picture-card"
                      class="avatar-uploader"
                      :show-upload-list="false"
                      v-decorator="['imgIdF', {rules: [{required: true, message: '请上传封面！'}]}]"
                      :before-upload="beforeUpload"
                      @change="handleChange"
                  >
                      <img v-if="imgF" :src="imgF.fileUrl" alt="coverAvatar" width="100%"/>
                      <div v-else>
                      <a-icon :type="coverLoading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">
                          上传
                      </div>
                      </div>
                  </a-upload>
              </a-form-item>
              <a-form-item label="密码" v-if="!editDisabled">
                <a-input-password v-decorator="['password', {rules: [{required: true,  message: '请输入密码！'},{pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/,message:'至少8位数字且包含大小写字母及数字'}]}]" />
              </a-form-item>
              <a-form-item label="手机号">
                <a-input :disabled="editDisabled" v-decorator="['phone',{rules: [{ required: true, message: '请输入手机号！' },{pattern:/^1[3456789]\d{9}$/,message:'请输入正确的手机号' }]}]" />
              </a-form-item>

              <a-form-item label="验证码" v-if="!editDisabled">
                <a-input-search v-decorator="['code',{rules: [{ required: true, message: '请输入验证码' }],},]" @search="getCode">
                  <a-button slot="enterButton" :disabled="codeBtnDisabled() || isSending">
                    {{codeTxt}}
                  </a-button>
                </a-input-search>
              </a-form-item>
              <a-form-item :wrapper-col="{ span: 24, offset: 0 }">
                <a-button type="primary" class="defBut" html-type="submit">
                  注册
                </a-button>
                <router-link :to="{name:'Login'}"><a-icon type="rollback"/>返回登录</router-link>
              </a-form-item>
            </a-form>
          </a-spin>
        </div>
      </div>
    </div>
    <div class="botBox">
      <div class="footerLink">
        <div class="leftIcon">
          <img src="../../static/images/mingtai/botLogo.png" />
        </div>
        <div class="rightList">
          <div class="linkTitle">艾灸须知</div>
          <div class="linkBox">
            <div>
              <a>1.艾灸禁忌</a>
            </div>
            <div>
              <a>2.如何辨别艾条质量</a>
            </div>
            <div>
              <a>3.艾灸的功效</a>
            </div>
            <div>
              <a>4.艾灸的时间</a>
            </div>
            <div>
              <a>5.艾灸的身体顺序</a>
            </div>
            <div>
              <a>6.艾灸注意事项</a>
            </div>
          </div>
        </div>
      </div>
      <s-footer />
    </div>
  </div>
</template>

<script>
import { Base64 } from "@/util/util";
import axios from 'axios';
import sFooter from "@/view/components/Footer";

var base64 = new Base64();
export default {
  components: {
    sFooter,
  },
  data() {
    return {
      userObj: {
        account: null,
        password: null,
        moblie: null,
      },
      codeTxt: "发送验证码",
      count: 60,
      form: this.$form.createForm(this),
      tabData: ["个人注册", "企业注册"],
      tabIndex: 0,
      imgF:null,
      coverLoading: false,
      isSending: false,
      confirmLoading: false,
      editDisabled: false,
      pid: null,
    };
  },
  mounted() {
    //来自企业账号的分享链接
    let query = this.$route.query
    if(query.pid){
      this.pid = query.pid
    }

    let params = this.$route.params;
    if(Object.keys(params).length>0){
      this.form = this.$form.createForm(this)
      this.editDisabled = true
      this.$api.registerInfo(null,params).then(res => {
        if(res.success){
          let record = res.data
          this.tabIndex = record.type==2?1:0
          this.imgF = record.licenseFile
          setTimeout(()=>{
            this.form.setFieldsValue(
              {
                id:record.id,
                name:record.name,
                phone:record.phone,
              }
            );
            if(this.tabIndex==0){
              this.form.setFieldsValue({idCard:record.idCard})
            }else{
              this.form.setFieldsValue({imgIdF:record.licenseFile})
            }
          },100)
        }else{
          this.$message.error(res.message)
        }
      })
    }else{
      this.editDisabled = false
    }
  },
  created() {
    this.isSending = false
    this.codeTxt = '发送验证码'
  },
  methods: {
    codeBtnDisabled(){
      let result = true
      this.form.validateFields(['phone'],(err,values) => {
        if (!err && values.phone) {
          result = false
        }
      })
      return result
    },
    tabCk(index) {
      if(this.pid && index==1){
        this.$message.info('只允许注册个人账号')
        return
      }
      this.tabIndex = index;
    },
    getCode() {
      if(this.timer){
        clearInterval(this.timer);
      }
      const TIME_COUNT = 60;
      let phone = this.form.getFieldValue('phone')
      this.$api.sendSmsCode({phoneNumbers:phone},null).then(res => {
        if(res.success){
          this.$message.success('短信发送成功！')
          this.isSending = true
          this.timer = setInterval(() => {//开启倒计时
            if (this.count > 1 && this.count <= TIME_COUNT) {
              this.count -= 1;
              this.codeTxt = this.count+'秒后重发';
            } else {//倒计时结束
              clearInterval(this.timer);
              this.isSending = false
              this.codeTxt = "再次发送";
              this.count = 60;
            }
          }, 1000);
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSubmit() {
      this.confirmLoading = true
      const { form: { validateFields } } = this
      validateFields((err,values) => {
        if(!err){
          values.type = this.tabIndex==0?1:2
          if(this.imgF){
            values.licenseId = this.imgF.id
          }
          values.pid = this.pid
          this.$api.register(null,values).then(res => {
            if(res.success){
              this.$message.success('注册成功，请等待审核...')
              this.$router.push({name:'Login'})
            }else{
              this.$message.error(res.message)
            }
          }).finally((res) =>{
              this.confirmLoading = false
          })
        }else{
          this.confirmLoading = false
        }
      })
    },

    //图片上传
    handleChange(info) {
        if (info.file.status === 'uploading') {
            this.coverLoading = false;
        }
        if (info.file.status === 'done') {
            this.coverLoading = false;
        }
      },
      customRequestCover(data){
          this.customRequest(data,'cover')
      },
      customRequest(data,type){
        let formData = new FormData()
        formData.append('file', data.file)
        axios.post('/api/sysFileInfo/upload7Cloud',formData).then((result) => {
          let res = result.data
          if (res.success) {
              if(type=='cover'){
                  this.imgF = res.data
              }
              this.$message.success('上传成功');
              data.onSuccess() //上传成功
          } else {
              this.$message.error('上传失败：' + res.message);
              data.onError() //上传失败
          }
        })
    },
    beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
            this.$message.error('图片大小不能超过20MB!');
        }
        return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
// 公共样式
.hide {
  display: none;
}
.show {
  display: block;
}
a {
  color: #656565;
}
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  font-size: 0;
  .topBox {
    height: 110px;
    display: flex;
    box-sizing: border-box;
    padding: 0px 100px;
    align-items: flex-end;
    position: relative;
    width: 100%;
    .leftImg {
      width: 350px;
      height: 80px;
    }
    .rightImg {
      width: 450px;
      height: 40px;
      position: absolute;
      right: 100px;
      top: 50%;
      margin-top: -10px;
    }
  }
  .centBox {
    height: calc(100% - 300px);
    background-image: url("../../static/images/mingtai/loginBg.png");
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    .tabBox {
      display: flex;
      height: 50px;
      width: 100%;
      color: #063a61;
      font-size: 16px;
      line-height: 50px;
      gap: 20px;
      div {
        flex: 30%;
        background-color: #063a61;
        position: relative;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }
      div:first-child:after {
        position: absolute;
        content: "";
        right: -10px;
        top: 0px;
        border-bottom: 50px solid #063a61;
        border-left: 0px solid transparent;
        border-right: 10px solid transparent;
        // border-bottom-right-radius: 5px;
      }
      div:last-child:after {
        position: absolute;
        content: "";
        right: auto;
        left: -10px;
        top: 0px;
        border-bottom: 50px solid #063a61;
        border-left: 10px solid transparent;
        border-right: 0px solid transparent;
      }
      .active {
        background-color: white;
        color: #063a61;
        cursor: pointer;
        font-size: 16px;
      }
      .active:after {
        border-bottom: 50px solid white !important;
      }
    }
    .loginBox {
      position: absolute;
      width: 450px;
      left: 50%;
      top: 50%;
      margin-top: -255px;
      .boxs {
        background-color: white;

        width: 100%;
        height: 100%;
        padding: 15px 25px;
        .inpBox {
          margin-top: 10px;
          label {
            font-size: 14px;
            color: #bcbcbc;
            width: 70px;
            display: inline-block;
            text-align: right;
          }
          input {
            border: 1px solid #e6e6e6;
            font-size: 14px;
            padding: 10px;
            color: #b2a285;
            margin-left: 10px;
            width: calc(100% - 80px);
            font-family: 微软雅黑;
          }
        }
        .codeBox {
          input {
            width: calc(100% - 200px);
          }
          button {
            width: 100px;
            color: white;
            padding: 10px;
            border: 1px solid #063a61;
            background-color: #063a61;
            font-size: 14px;
            margin-left: 20px;
            cursor: pointer;
            font-family: 微软雅黑;
          }
        }

        .defBut {
          width: 100%;
          font-family: 微软雅黑;
          background-color: #063a61;
          height: 35px;
          color: white;
          border: 0px;
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 5px;
        }
      }
    }
  }
  .botBox {
    height: 161px;
    background-color: #063a61;
    position: absolute;
    width: 100%;
    margin-top: -11px;
    .footerLink {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-left: 500px;
      box-sizing: border-box;
      .leftIcon {
        img {
          width: 90px;
        }
      }
      .rightList {
        margin-left: 40px;
        .linkTitle {
          color: white;
          font-size: 22px;
          font-weight: bold;
          text-align: left;
        }
        .linkBox {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 300px;
          height: 70px;
          text-align: left;
          div {
            flex: 33%;
            height: 20px;
            font-size: 14px;
            a {
              color: #d2dbe2;
            }
            color: #d2dbe2;
          }
        }
      }
    }
  }
}
</style>