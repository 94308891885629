import { render, staticRenderFns } from "./reg.vue?vue&type=template&id=61af8482&scoped=true&"
import script from "./reg.vue?vue&type=script&lang=js&"
export * from "./reg.vue?vue&type=script&lang=js&"
import style0 from "./reg.vue?vue&type=style&index=0&id=61af8482&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61af8482",
  null
  
)

export default component.exports